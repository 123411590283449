import React from "react";
import { makeStyles, TextField, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  row: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  textarea: {
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
      width: "100%",
    },
  },
  submit: {
    marginTop: theme.spacing(2),
    paddingLeft: "5em",
    paddingRight: "5em",
  },
}));

const IndividualForm = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <form
      className={classes.root}
      name="contact"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value="contact" />
      <input type="hidden" name="bot-field" />
      <div className={classes.row}>
        <TextField
          name="first-name"
          id="first-name"
          label={t("contact-us.first-name-label")}
          required
        />
        <TextField
          name="last-name"
          id="last-name"
          label={t("contact-us.last-name-label")}
          required
        />
      </div>
      <div className={classes.row}>
        <TextField
          type="email"
          name="email"
          id="email"
          label={t("contact-us.email-label")}
          required
        />
        <TextField
          type="tel"
          name="phone"
          id="phone"
          label={t("contact-us.phone-label")}
          required
        />
      </div>
      <div className={classes.textarea}>
        <TextField
          multiline
          rows={5}
          placeholder={t("contact-us.message-placeholder")}
          name="message"
          id="message"
          label={t("contact-us.message-label")}
          required
        />
      </div>
      <Button
        type="submit"
        variant="contained"
        color="secondary"
        className={classes.submit}
        size="large"
      >
        {t("contact-us.submit")}
      </Button>
    </form>
  );
};

export default IndividualForm;
