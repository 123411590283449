import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { useTranslation } from "react-i18next";
import {
  Container,
  Link,
  makeStyles,
  Paper,
  Tab,
  Typography,
} from "@material-ui/core";
import IndividualForm from "../components/contact-us/individual-form";
import BusinessForm from "../components/contact-us/business-form";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Address from "../components/address";
import { graphql } from "gatsby";

const useStyles = makeStyles(theme => ({
  centered: {
    textAlign: "center",
  },
  upper: {
    textTransform: "uppercase",
  },
  section: {
    marginBottom: "2em",
  },
  paper: {
    padding: theme.spacing(2),
  },
  tabPanel: {
    padding: 0,
  },
  officeInfo: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "center",
      "& :first-child": {
        marginRight: "3em",
      },
    },
  },
}));

const ContactUs = ({
  data: {
    site: { siteMetadata },
  },
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [formMode, setFormMode] = React.useState("individual");

  return (
    <Layout>
      <SEO title="Contact Us" />
      <Container maxWidth="md">
        <div className={classes.section}>
          <Typography variant="h3" gutterBottom className={classes.centered}>
            {t("contact-us.office-title", { title: siteMetadata.title })}
          </Typography>
          <Paper className={classes.paper}>
            <div className={classes.officeInfo}>
              <div>
                <Typography variant="h6" gutterBottom className={classes.upper}>
                  {t("contact-us.address")}
                </Typography>
                <Address address={siteMetadata.address} />
              </div>

              <div>
                <Typography variant="h6" gutterBottom className={classes.upper}>
                  {t("contact-us.contact-info")}
                </Typography>
                <div>
                  <Typography>
                    <b>{t("contact-us.email-label")}:</b>{" "}
                    <Link href={`mailto:${siteMetadata.email}`} target="_top">
                      {siteMetadata.email}
                    </Link>
                  </Typography>
                  <Typography>
                    <b>{t("contact-us.phone-label")}:</b> {siteMetadata.phone}
                  </Typography>
                </div>
              </div>
            </div>
          </Paper>
        </div>

        <div className={classes.section}>
          <Typography variant="h3" className={classes.centered}>
            {t("contact-us.title")}
          </Typography>
          <Typography
            typography="body1"
            gutterBottom
            className={classes.centered}
          >
            {t("contact-us.description")}
          </Typography>

          <Paper className={classes.paper}>
            <TabContext value={formMode}>
              <TabList
                value={formMode}
                onChange={(_, value) => setFormMode(value)}
                aria-label="contact person type tabs"
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label={t("contact-us.individual")} value="individual" />
                <Tab label={t("contact-us.business")} value="business" />
              </TabList>

              <TabPanel value="individual" className={classes.tabPanel}>
                <IndividualForm />
              </TabPanel>
              <TabPanel value="business" className={classes.tabPanel}>
                <BusinessForm />
              </TabPanel>
            </TabContext>
          </Paper>
        </div>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query SiteTitleQueryContactUs($language: String!) {
    site {
      siteMetadata {
        title
        author
        email
        phone
        address {
          street
          suite
          city
          state
          zip
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default ContactUs;
